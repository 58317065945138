<template>
  <div class="item-box">
    <div class="img-box">
      <img :src="info.albumCover" />
      <span class="tip">
        <span>已更新{{ info.articleNum }}期</span>
        <span>阅读量{{ info.viewNum }}</span>
      </span>
    </div>
    <div class="title">{{ info.albumName }}</div>
    <div class="sub-title">{{ info.albumDesc }}</div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.item-box {
  width: 160px;
  height: 170px;
  position: relative;
  display: inline-block;
  white-space: normal;
}

.img-box {
  width: 148px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.img-box img {
  width: 100%;
  height: 100%;
}

.img-box .tip {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  border-radius: 0px 0px 8px 8px;
  font-size: 10px;
  font-weight: 400;
  height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 34px;
  color: #1a1a1a;
}

.sub-title {
  display: inline-block;
  width: 150px;
  height: 36px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #808080;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}
</style>