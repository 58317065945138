<template>
  <div>
    <title-item titleName="热门专题" :isShow="false"></title-item>
    <div class="special-box" v-if="list.length > 0">
      <div class="special-top" @click="goto(list[0].id)">
        <div class="top-line">
          <div class="top-title">{{ list[0].topicTag }}</div>
          <img class="icon" src="@/assets/imgs/index/icon-6D779B.png" />
          <div>已更新{{ list[0].articleNum }}期</div>
        </div>
        <div class="sub-title">{{ list[0].topicName }}</div>
        <div>{{ list[0].topicDesc }}</div>
      </div>
      <div class="special-bottom">
        <div class="special-left" @click="goto(list[1].id)">
          <div>
            <div class="left-title">{{ list[1].topicTag }}</div>
            <div class="left-name">{{ list[1].topicName }}</div>
            <div>{{ list[1].topicDesc }}</div>
          </div>
          <div style="display: flex; align-items: center">
            <img class="icon" src="@/assets/imgs/index/icon-C28FC9.png" />
            <div>已更新{{ list[1].articleNum }}期</div>
          </div>
        </div>
        <div class="special-right">
          <div class="right-top" @click="goto(list[2].id)">
            <div class="right-top-title">{{ list[2].topicTag }}</div>
            <div class="sub-right__title">{{ list[2].topicName }}</div>
            <div style="display: flex; align-items: center">
              <img class="icon" src="@/assets/imgs/index/icon-EFC098.png" />
              <div>已更新{{ list[2].articleNum }}期</div>
            </div>
          </div>

          <div class="right-bottom" @click="goto(list[3].id)">
            <div class="right-bottom-title">{{ list[3].topicTag }}</div>
            <div class="sub-right__title">{{ list[3].topicName }}</div>
            <div style="display: flex; align-items: center">
              <img class="icon" src="@/assets/imgs/index/icon-6D779B.png" />
              <div>已更新{{ list[3].articleNum }}期</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleItem from "./TitleItem.vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TitleItem,
  },
  data() {
    return {};
  },
  methods: {
    goto(id) {
      this.$router.push({ name: "Topic", query: { id } });
    },
  },
};
</script>

<style scoped>
.special-box {
  margin-top: 10px;
}

.special-top {
  background-image: url("../../assets/imgs/index/bk1.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12px;
  border-radius: 10px;
  color: #6D779B;
  text-align: left;
  opacity: 0.8;
}

.top-line {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.top-title {
  padding: 1px 6px;
  border-radius: 8px;
  background: #6D779B;
  color: white;
}

.icon {
  width: 12px;
  height: 12px;
  margin: 0 4px 0 8px;
}

.special-bottom {
  margin-top: 8px;
  display: flex;
}

.special-left {
  padding: 12px;
  background-image: url("../../assets/imgs/index/bk2.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  font-size: 12px;
  text-align: left;
  color: #CB8ED4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-title {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 8px;
  background: #C28FC9;
  color: white;
}

.left-name {
  font-size: 20px;
  font-weight: 700;
  margin: 16px 0 2px 0;
}

.special-right {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.right-top {
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
  border-radius: 8px;
  background-image: url("../../assets/imgs/index/bk3.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 12px;
  color: #E2A470;
}

.right-top-title {
  color: white;
  display: inline-block;
  padding: 1px 8px;
  background: #EFC098;
  border-radius: 8px 0px 14px 0px;
}

.right-bottom {
  width: 170px;
  font-size: 12px;
  text-align: left;
  border-radius: 8px;
  background-image: url("../../assets/imgs/index/bk4.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 12px;
  color: #719DC9;
}

.right-bottom-title {
  color: white;
  display: inline-block;
  padding: 1px 8px;
  background: #719DC9;
  border-radius: 8px 0px 14px 0px;
}

.sub-title {
  font-size: 20px;
  font-weight: 700;
  margin: 12px 0 4px 0;
}

.sub-right__title {
  font-size: 20px;
  font-weight: 700;
  margin: 12px 0px 12px 12px;
}
</style>