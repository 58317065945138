import { get } from './http.js'

/**
 * 获取首页
 */
export const getHome = () => get('/article/v1.0/index')

/**
 * 获取文章列表
 */
export const getArticleList = params => get('/article/v1.0', params)