<template>
  <div class="top-box">
    <div>
      <img class="avatar" :src="userInfo.avatar" />
      <span class="nickname van-ellipsis">{{ userInfo.nickName }}</span>
    </div>
    <div>
      <img class="icon-box" src="@/assets/imgs/index/comment.png" >
      <span @click="readComment">评论</span>
      <div class="read" v-if="redShow"></div>
      <div class="line"></div>
      <img class="icon-box" src="@/assets/imgs/index/questions.png" />
      <span @click="$router.push({ name: 'QuestionTab' })">提问</span>
      <div class="line"></div>
      <img class="icon-box" src="@/assets/imgs/index/collect2.png" />
      <span @click="$router.push({ name: 'Favorite' })">收藏</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import to from "@/utils/to";
import { getUserReadTips, getAuthorReadTips } from "@/api/mine";
export default {
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      redShow: false,
    }
  },
  mounted() {
    if (this.userInfo.author) {
        this.getAuthorTips();
      } else{
        this.getUserTips();
      }
  },
  methods: {
    readComment() {
      if (this.userInfo.author) {
        this.$router.push({ name: 'AuthorCommentsManagementPage' });
      } else {
        this.$router.push({ name: 'UserCommentsManagementPage' });
      }
    },
    async getUserTips() {
      let [err, res] = await to(getUserReadTips());
      if (err) {
        return
      }

      this.redShow = res > 0 ? true : false
      
    },
    async getAuthorTips() {
      let [err, res] = await to(getAuthorReadTips());
      if (err) {
        return;
      }

      this.redShow = res > 0 ? true : false
    }
  }
};
</script>

<style scoped>
.top-box {
  margin-top: 5px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.top-box > div {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
}

.nickname {
  width: 100px;
  color: #333;
}

.icon-box {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.line {
  margin: 0 6px;
  height: 12px;
  width: 1px;
  border-left: 0.5px solid #b3b3b3;
}

.read {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-left: 50px;
  background: #FF4141;
}
</style>