<template>
  <div>
    <title-item
      titleName="优选专栏"
      :isShow="true"
      @click.native="$router.push({ name: 'AlbumList' })"
    ></title-item>
    <div class="album-list">
      <album-item
        v-for="item in list"
        :key="item.albumId"
        :info="item"
        @click.native="$router.push({ name: 'Album', query: { id: item.albumId } })"
      ></album-item>
    </div>
  </div>
</template>

<script>
import TitleItem from "./TitleItem.vue";
import AlbumItem from "./AlbumItem.vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AlbumItem,
    TitleItem,
  },
};
</script>

<style scoped>
.album-list {
  margin: 8px 0;
  min-width: 358px;
  width: 100%;
  height: 170px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>