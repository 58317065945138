<template>
  <van-swipe class="swipe" :autoplay="10000" indicator-color="white">
    <van-swipe-item v-for="(img, idx) in list" :key="idx" @click="onClick(img)">
      <img v-lazy="img.bannerUrl" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  methods: {
    onClick(img) {
      const { bannerParams } = img;
      if (bannerParams != null) {
        this.$router.push(JSON.parse(bannerParams).h5Path);
      }
    },
  },
};
</script>

<style scoped>
.swipe {
  margin-top: 12px;
  border-radius: 10px;
  overflow: hidden;
}

.swipe >>> .van-swipe-item {
  border-radius: 10px;
  width: 344px;
  height: 150px;
}

.swipe img {
  width: 100%;
  height: 100%;
}
</style>