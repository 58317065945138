<template>
  <div class="item-box">
    <div class="head-div">{{ titleName }}</div>
    <div v-if="isShow" style="display: flex; align-items: center">
      <div style="line-height: 10px">查看更多</div>
      <van-icon color="#808080" size="14" name="arrow" />
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
export default {
  props: {
    titleName: String,
    isShow: Boolean,
  },
  components: {
    [Icon.name]: Icon,
  },
};
</script>

<style scoped>
.item-box {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #808080;
}

.head-div {
  font-size: 20px;
  font-weight: bold;
  color: #1a1a1a;
}
</style>