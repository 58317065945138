<template>
  <div class="page">
    <van-skeleton :row="20" :loading="loading">
      <user />
      <banner :list="home.bannerList"></banner>
      <img
        class="qa-box"
        src="@/assets/imgs/index/banner2.png"
        @click="$router.push({ name: 'ExpertList' })"
      />

      <album-container :list="home.albumList" class="box"></album-container>
      <topic-container :list="home.topicList" class="box1"></topic-container>

      <article-list class="box1"></article-list>
    </van-skeleton>
    <div v-if="showAudioBar" class="audio-bar">
      <audio-bar @click="onAudioBarClick" @close="onAudioBarCloseClick"></audio-bar>
    </div>
  </div>
</template>

<script>
import User from "./User.vue";
import Banner from "./Banner";
import AlbumContainer from "./AlbumContainer";
import ArticleList from "./ArticleList";
import { getHome } from "@/api/home";
import to from "@/utils/to";
import TopicContainer from "./TopicContainer";
import AudioBar from '@/components/AudioBar'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Skeleton } from "vant";
import wxShareMixin from '@/mixins/wx_share_mixin'

export default {
  components: {
    [Skeleton.name]: Skeleton,
    User,
    Banner,
    AlbumContainer,
    ArticleList,
    TopicContainer,
    AudioBar
  },
  mixins: [wxShareMixin],
  data() {
    return { home: {}, loading: false };
  },
  computed: {
    ...mapState('audio', [
      'status'
    ]),
    ...mapGetters('audio', [
      'curInfo'
    ]),
    showAudioBar() {
      return this.status === 'playing'
      || this.status === 'pause'
      || this.status === 'end' ? true : false
    }
  },
  mounted() {
    this.getHome();
  },
  methods: {
    async getHome() {
      this.loading = true;
      let [err, res] = await to(getHome());
      this.loading = false;

      if (err != null) {
        console.log("加载错误", err);
        return;
      }
      this.home = res;
      const shareCfg = {
            title: "🌍河蟹星球🌍",
            desc: "专注于人社政策法规解读和劳动关系管理的人力资源管理公益性互动学习平台",
            link: location.href,
            imgUrl: "https://annualr.oss-cn-chengdu.aliyuncs.com/hxxq/logo.jpg"
        }
        this.getWS(shareCfg)
    },
    onAudioBarClick() {
      this.$router.push({
        name: 'Article',
        query: {
          id: this.curInfo.id,
          from: 'abar'
        }
      })
    },
    onAudioBarCloseClick() {
      this.setStatus('stop')
    },
    ...mapMutations('audio', [
      'setStatus'
    ])
  },
};
</script>

<style scoped lang="less">
.page {
  padding: 0 16px 16px 16px;
}

.qa-box {
  margin-top: 16px;
  height: 49px;
}

.box {
  margin-top: 22px;
}

.box1 {
  margin-top: 20px;
}

&::-webkit-scrollbar {
  display: none;
}

.audio-bar {
  position: fixed;
  /* width: 80%; */
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0 auto;
}
</style>