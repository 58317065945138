<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="已经到底了"
    @load="onLoad"
  >
    <div v-for="(vlaue, key) in articleCatalog" :key="key">
      <div class="time-box">{{ key }}</div>
      <div
        class="item-box"
        v-for="article in articleCatalog[key]"
        :key="article.id"
        @click="
          $router.push({ name: 'Article', query: { id: article.id } })
        "
      >
        <img class="cover" :src="article.coverUrl" />
        <div class="item-right">
          <div class="play-box">
            <div>
              <div style="color: #999">{{ article.albumName }}</div>
              <div class="title">{{ article.title }}</div>
            </div>
            <img class="play" src="@/assets/imgs/index/play1.png" />
          </div>

          <div class="comment-box" v-if="article.lastComment != null">
            {{ article.lastComment }}
          </div>

          <div class="bottom-box">
            <div>
              <div class="avatars-box" v-if="article.lastAvatar.length > 0">
                <img
                  class="avatar-img"
                  v-for="(avatar, idx) in article.lastAvatar"
                  :src="avatar"
                  :key="idx"
                />
              </div>
              <div>{{ article.readingNum }}听过</div>
            </div>

            <div>
              <div class="collect-box">
                <img class="icon" src="@/assets/imgs/index/collect.png" />
                <div>{{ article.favNum }}</div>
              </div>
              <div class="line"></div>
              <div class="comment-num">
                <img class="icon" src="@/assets/imgs/index/comment.png" />
                <div>{{ article.commentNum }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-list>
</template>

<script>
import { List } from "vant";
import { getArticleList } from "@/api/home";
import to from "@/utils/to";

export default {
  components: {
    [List.name]: List,
  },
  data() {
    return {
      articleCatalog: {},
      loading: false,
      finished: false,
      pageNum: 1,
    };
  },
  methods: {
    async onLoad() {
      this.loading = true;

      let [err, res] = await to(getArticleList({ pageNum: this.pageNum }));
      this.loading = false;

      if (err != null) {
        console.log("加载错误", err);
        this.finished = true;
        return;
      }

      if (res.rows.length <= 0) {
        this.finished = true;
        return;
      }

      res.rows.forEach((article) => {
        const { publishDate } = article;
        let list = this.articleCatalog[publishDate];
        if (list == undefined) {
          list = [];
          this.articleCatalog[publishDate] = list;
        }
        list.push(article);
      });
      this.pageNum = res.pageNum + 1;
    },
  },
};
</script>

<style scoped>
.time-box {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: #333333;
  text-align: left;
  margin: 12px 0;
}

.item-box {
  display: flex;
  margin-bottom: 20px;
}

.cover {
  max-width: 70px;
  max-height: 70px;
  border-radius: 10%;
}

.item-right {
  width: 100%;
  margin-left: 8px;
  font-size: 14px;
  text-align: left;
}

.play-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.play {
  width: 30px;
  height: 30px;
}

.title {
  margin-top: 6px;
  width: 178px;
  font-size: 16px;
  font-weight: 800;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.comment-box {
  margin-top: 12px;
  padding: 12px;
  background: #F3F7FA;
  color: #999999;
  position: relative;
  border-radius: 6px;
}

.comment-box::before {
  content: "";
  width: 0;
  height: 0;
  border: 6px solid;
  position: absolute;
  top: -12px;
  left: 12px;
  border-color: transparent transparent #f5f5f5 transparent;
}

.bottom-box {
  margin-top: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
}

.bottom-box > div {
  display: flex;
  align-items: center;
}

.avatars-box {
  max-width: 60px;
  margin-right: 8px;
  padding-left: 10px;
}

.collect-box {
  display: flex;
  align-items: center;
}

.line {
  margin: 0 6px;
  height: 12px;
  width: 1px;
  border-left: 0.5px solid #b3b3b3;
  opacity: 0.4;
}

.comment-num {
  display: flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.avatar-img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: -8px;
}
</style>