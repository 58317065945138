<template>
    <div class="audio-bar">
        <img v-if="status === 'pause'" @click="onClose"
            class="audio-bar__close"
            src="@/assets/imgs/playbar/close.png"/>
        <div class="audio-bar__meta" @click="onBarClick">
            <img class="audio-bar__cover"
                :src="audioInfo.coverUrl" />
            <div class="audio-bar__info">
                <div class="audio-bar__title">{{audioInfo.title}}</div>
                <div class="audio-bar__author">
                    {{totalShowTime}} | {{audioInfo.author}}
                </div>
            </div>
        </div>
        <div class="audio-bar__ctrl">
            <van-circle :value="percent100" @click="onPlay"
                :stroke-width="60"
                layer-color="#cfcfcf"
                color="#fff"
                class="audio-bar__circle">
                <img class="audio-bar__play"
                    @click="onPlay"
                    :src=" status === 'pause'? playIcon : pauseIcon"/>
            </van-circle>

            <img class="audio-bar__next"
                src="@/assets/imgs/playbar/forward.png" @click="onNext" />
        </div>
    </div>
</template>

<script>
import { Circle } from 'vant'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    components: {
        [Circle.name]: Circle
    },
    computed: {
        ...mapState('audio', [
            'status',
            'playList',
            'curIdx'
        ]),
        ...mapGetters('audio', [
            'totalShowTime',
            'percent'
        ]),
        percent100() {
            return this.percent * 100
        },
        audioInfo() {
            return this.playList[this.curIdx]
        }
    },
    watch: {
        status(val) {
            if (val === 'end') {
                this.onNext()
            }
        },
    },
    data() {
        return { 
            playIcon: require('@/assets/imgs/playbar/play.png'),
            pauseIcon: require('@/assets/imgs/playbar/pause.png')
        }
    },
    methods: {
        onPlay() {
            console.log('onPlay')
            const status = this.status === 'playing' ? 'pause' : 'playing'
            this.setStatus(status)
        },
        onClose() {
            console.log('onClose')
            this.$emit('close')
        },
        onBarClick() {
            console.log('onClick')
            this.$emit('click')
        },
        onNext() {
            console.log('onNext')
            const idx = this.curIdx + 1
            if (idx >= this.playList.length) {
                this.$toast('已经是最后一支音频了')
                return
            }
            this.play(idx)
        },
        ...mapMutations('audio', [
            'play',
            'setStatus',
            'setPercent'
        ])
    }
}
</script>

<style scoped>
.audio-bar {
    padding: 8px 10px;
    border-radius: 4px;
    background: #aaa;
    width: 345px;
    opacity: 0.9;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.audio-bar__meta {
    display: flex;
    align-items: center;
}

.audio-bar__cover {
    width: 42px;
    height: 42px;
    background-color: #ccc;
    border-radius: 2px;
    margin-right: 9px;
}

.audio-bar__info {
    color: #fff;
}

.audio-bar__title {
    font-size: 14px;
    font-weight: 700;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.audio-bar__author {
    font-size: 10px;
    margin-top: 4px;
    color: #d0d0d0;
}

.audio-bar__ctrl {
    display: flex;
    align-items: center;
    margin-right: 9px;
    color: #fff;
    font-size: 12px;
}

.audio-bar__close {
    width: 20px;
    height: 20px;
    color: #cfcfcf
}

.audio-bar__circle {
    width: 34px !important;
    height: 34px !important;
    position: relative;
}

.audio-bar__play {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.audio-bar__next {
    width: 14px;
    height: 14px;
    margin-left: 20px;
}

</style>